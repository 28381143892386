<template>
    <div>
        <br>
        <p class="font-semibold">Transaksi Online</p>
        <br>
        <br>
        <br>
        <div class="row justify-content-center mb-3" v-if="dipilih">
          <div class="col-md-6">
            <div class="shadow rounded-lg p-3">
                <p class="font-semibold">Nota : {{dipilih.nota}}</p>
                <hr>
                <table class="table table-sm">
                    <tr>
                        <td>
                            Ubah Status : 
                        </td>
                         <td>
                            <button type="button"  class="btn btn-sm btn-warning" @click="proses('1')">Diproses</button>
                        </td>
                        <td>
                            <button type="button"  class="btn btn-sm btn-primary" @click="proses('2')">Dikirim</button>
                        </td>
                        <td>
                            <button type="button"  class="btn btn-sm btn-success" @click="proses('3')">Selesai</button>
                        </td>
                    </tr>
                </table>
            </div>
          </div>
        </div>
        <div class="sm-form ">
            <label for="search">Cari Nota</label>
            <input type="text" id="search" name="search" class="form-control form-control-sm" placeholder="search" v-model="search" >
        </div>
        <div class="row justify-content-center">
          <div class="col-md-12">
              <div class="shadow rounded-lg p-3 bg-white">
              <div class="table-responsive">
                  <table class="table table-sm table-bordered table-striped">
                      <tr>
                          <th>No</th>
                          <th>Tanggal</th>
                          <th>Nota</th>
                          <th>Pelanggan</th>
                          <th>Total Pembelian</th>
                          <th>Biaya kirim</th>
                          <th>Method Pembayaran</th>
                          <th>Catatan</th>
                          <th>Alamat</th>
                          <th>Status</th>
                          <th>Map</th>
                      </tr>
                      <tr v-for="(item, index) in td" :key="index+'td'">
                          <td>{{index+1}}</td>
                          <td>{{item.tanggal}}</td>
                          <td>{{item.nota}}</td>
                          <td>{{users.filter(e=>e.id==item.id_pelanggan)[0].email}}</td>
                          <td>{{item.total}}</td>
                          <td>{{item.biayakirim}}</td>
                          <td>{{item.method}}</td>
                          <td>{{item.catatan}}</td>
                          <td>{{item.alamat}}</td>
                          <td>
                            <button @click="pilih(item)" type="button" class="btn btn-sm " :class="`${item.status=='1'?'btn-warning':item.status=='2'?'btn-primary':item.status=='3'?'btn-success':item.status=='4'||item.status=='8'?'btn-dark':item.status=='5'?'btn-outline-danger':'btn-danger'}`">
                                {{item.status=='1'?'Proses':item.status=='2'?'Dikirim':item.status=='3'?'Sampai':item.status=='4'||item.status=='8'?'Pilih Method Bayar':item.status='5'?'Dibayar':item.status=='8'?'Belum Dibayar':'Batal'}}
                            </button>
                          </td>
                          <td><button type="button"  class="btn btn-sm btn-primary  ">Map <span class="typcn typcn-map"></span></button></td>
                      </tr>
                  </table>
              </div>
              </div>
          </div>
        </div>
     <div class="d-block d-sm-none"><br><br><br><br></div>
    </div>
</template>
<script>
import autophp from '@/plugins/autophp_kasir';
let sdb = new autophp();
import uniqid from 'uniqid'
import firebase from 'firebase'
let rdb = firebase.database();
export default{
    layout:'app_shop_admin',
    data(){
        return{
            datanya:[],
            dipilih:false,
            search:'',
            users:[]
        }
    },
    computed:{
        td(){
            let that=this
            let data = this.datanya;
            data=data.filter(e=>{
                if(e.nota.toLowerCase().indexOf(that.search.toLowerCase())!=-1){
                    return e
                }
            })
            return data
        }
    },
    methods: {
         async notifikasi(title,body,tujuan){
            await sdb.collection('app_kasir_notifikasi').doc().set({
                tujuan:tujuan,
                title:title,
                body:body,
                dibaca:'false'
            })
            rdb.ref('/notifikasi/admin').set({uniq:uniqid()})
            return true;
        },
        pilih(item){
            this.dipilih=item
            window.scrollTo(0, 0);
            this.$forceUpdate();
        },
        async proses(status){
            let data = {
                id:this.dipilih.id,
                status:status
            }
             await this.notifikasi(`Pembelian NoTa : ${this.dipilih.nota}`,
            `Progress Pembelian Telah ${status=='1'?'Diproses':status=='2'?'Dikirim':'Selesai'}`,
            this.dipilih.id_pelanggan
            )
            rdb.ref(`/notifikasi/${this.dipilih.id_pelanggan}`).set({uniq:uniqid()})
            sdb.collection('app_kasir_transaksi_nota').doc().set(data).then(res=>{
                alert('berhasil update!')
                  this.dipilih=false;
                  this.getData();        
            })
            
        },
        getData(){
            //doc adalah select *
            sdb.collection("tbuser").doc().get("*","order by id").then(res => {
                this.users=res;
            });
            
            sdb.collection("app_kasir_transaksi_nota",false).doc().select(`select * from app_kasir_transaksi_nota tb1  where tb1.beli='online' order by tb1.id desc`).then(res => {
                console.log('res',res)
                this.datanya=res;
                this.$forceUpdate();
            });
        }
    },
    mounted() {
    this.getData();        
    },
}
</script>